/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #757AE9;
  --ion-color-primary-rgb: 117,122,233;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 0,0,0;
  --ion-color-primary-shade: #676bcd;
  --ion-color-primary-tint: #8387eb;

  /** secondary **/
  --ion-color-secondary: #19CEAF;
  --ion-color-secondary-rgb: 25,206,175;
  --ion-color-secondary-contrast: #fff;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #16b59a;
  --ion-color-secondary-tint: #30d3b7;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82,96,255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #00AA55;
  --ion-color-success-rgb: 0,170,85;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #00964b;
  --ion-color-success-tint: #1ab366;

  /** warning **/
  --ion-color-warning: #e9cf75;
  --ion-color-warning-rgb: 233,207,117;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #cdb667;
  --ion-color-warning-tint: #ebd483;

  /** danger **/
  --ion-color-danger: #e97575;
  --ion-color-danger-rgb: 233,117,117;
  --ion-color-danger-contrast: #000000;
  --ion-color-danger-contrast-rgb: 0,0,0;
  --ion-color-danger-shade: #cd6767;
  --ion-color-danger-tint: #eb8383;

  /** dark **/
  --ion-color-dark: #28224B;
  --ion-color-dark-rgb: 40,34,75;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #231e42;
  --ion-color-dark-tint: #3e385d;

  /** medium **/
  --ion-color-medium: #7E828F;
  --ion-color-medium-rgb: 126,130,143;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #6f727e;
  --ion-color-medium-tint: #8b8f9a;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,245,248;
  --ion-color-light-contrast: #28224B;
  --ion-color-light-contrast-rgb: 40,34,75;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /* ionic */
  --ion-background-color: #F6F8FB;
  --ion-item-background: #ffffff;
  --ion-color-white: #ffffff;
  --ion-item-background-activated: #fafafa;
  --ion-toolbar-background: #ffffff;
  --ion-text-color: #28224B;


  /** custom **/
  --custom-modal-background: #fff;
  --custom-input-border: #EDF0F3;
  --custom-background: #fff;

  --box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #757AE9;
    --ion-color-primary-rgb: 117,122,233;
    --ion-color-primary-contrast: #fff;
    --ion-color-primary-contrast-rgb: 0,0,0;
    --ion-color-primary-shade: #676bcd;
    --ion-color-primary-tint: #8387eb;

    --ion-color-secondary: #19CEAF;
    --ion-color-secondary-rgb: 25,206,175;
    --ion-color-secondary-contrast: #fff;
    --ion-color-secondary-contrast-rgb: 0,0,0;
    --ion-color-secondary-shade: #16b59a;
    --ion-color-secondary-tint: #30d3b7;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;

    --ion-toolbar-background: #0d0d0d;
    --custom-background: #212121;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #222428;
    --ion-item-background-activated: #313131;

    --custom-modal-background: #121212;
    --custom-background: #212121;
  }

  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1a1b1e;
    --ion-item-background-activated: #313131;

    --custom-modal-background: #121212;
    --custom-background: #212121;
  }
}
