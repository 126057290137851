/**
 * To solve UI style issues quick and transparent.
 * Do NOT forget to exmplain what your hofix do in selector and/or in attached comment!
 */

/* Naive attempt to prevent body scroll, but with Ionic it's somehow unreachable  */
html, body {
  overflow: hidden;
}

/* Use Ionic colors */
p, h1, h2, h3, h4, h5, h6 {
  color: var(--ion-text-color)
}

/* Remove header shadow on Android   */
.header-md:after {
  content: initial;
}

/* Fix collapsable header forsed on to iOS mode */
.md .header-collapse-condense {
  display: inherit;
}
.md .header-collapse-condense ion-toolbar {
  position: initial;
}

/* Solving issue https://github.com/ionic-team/ionic/issues/20385 */
.ios body .hotfix-header-background {
  background-color: var(--ion-toolbar-background);
}
.md body .hotfix-header-background.header-md:after {
  display: none;
}

/* Remove Header bottom line on Home screen */
.header-ios ion-toolbar {
  --border-width: 0;
  --ion-border-color: transparent;
}

/* Make all screen contents have default styles
    like spacing, backgrounds, ... */
ion-app .ion-page ion-header > div {
  /* Basic spacing*/
  padding: 18px;
}

/* Modal UI */
@media only screen and (max-width: 768px) {
  ion-modal.show-modal {
    display: flex;
    align-items: flex-end;
  }
}
.modal-wrapper {
  --height: auto;
  height: auto !important;
  border-radius: 16px !important;
}
@media only screen and (max-width: 768px) {
  .modal-wrapper {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}
ion-modal.show-modal .ion-page {
  position: initial;
  contain: initial;
  padding: 16px;
  background: var(--custom-modal-background);
}

/* Fixing scroll issues
https://github.com/ionic-team/ionic/issues/17971#issuecomment-512734003 */
.backdrop-no-scroll ion-content {
    --overflow: hidden;
}

/* Fix desktop ugly MD buttons */
ion-button {
  text-transform: none;
  --border-radius: 4px; /* removing ugly border radius :D */
}

/* Propagate Ionic preference to classic elements */
p { color: var(--ion-color-dark) }


/* Make RippleEffect components works */
.ripple-parent {
  position: relative;
  overflow: hidden;
}

/* Some violance here. Victim: Ionic */
.force-button-size-by-parent {
  width: 100% !important;
  height: 100% !important;
  margin: 0
}

/* We wont IonFooter to have a border */
.remove-child-footer-border ion-footer::before {
  display: none;
}

/* Customize Ionic Footer for usage with submit button */
ion-footer:before {
  display: none !important;
}
ion-footer ion-toolbar {
  --background: transparent;
  --border-width: 0 !important;
}

/* Make modal submit buttons expaned in full width  */
ion-modal ion-button {
  margin: 0;
}

/* Tryin to style basic inputs by pure css */
input {
  width: 99.9%;
  background: var(--ion-color-light);
  border: 1px solid var(--ion-color-light-shade);
  box-sizing: border-box;
  border-radius: 2px;
  margin-top: 4px; /* Should ne on the label but like this is simplier */
  -webkit-appearance: none;
  font-size: 14px;
  padding: 8px; /* less space for very small devices */
  color: var(--ion-text-color);
}
@media (min-width: 375px) {
  /* More space on larger devices */
  input {
    padding: 16px;
  }
}

/* Compatible with input labels. I love to use basic html features for basic ux needs */
label {
  font-size: 16px;
  line-height: 18px;
  color: var(--ion-text-color);
}
label span {
  display: block;
  margin: 8px 0 4px !important;
}

/* Force some pages to use mobile viewport. Cheapest way how to do desktop version */
@media (min-width: 480px) and (min-height: 380px) {
  .ion-page.force-tablet-like-view  {
    max-width: 1380px;
    margin: 24px;
    max-height: 94vh;
    border-radius: 12px;
    box-shadow: 0px 9px 50px rgba(0,0,0,.10);
  }
}
@media (min-width: 1380px) {
  .ion-page.force-tablet-like-view  {
    margin: auto;
    box-shadow: 0px 9px 50px rgba(0,0,0,.10);
  }
}


/* Fit modals window to screen, (eq lots of order choices) */
ion-modal .modal-wrapper {
  --max-height: 88vh;
  --overflow: auto;
}

/* Remove ugly Ionic shadows for all light buttons */
ion-button[color="light"] {
  --box-shadow: none;
}


/* Remove spacing on content used as ValueItem's children  */
.value-item-container p {
  margin: 0;
}

/* Make date picker nicely fit to ValueItem */
ion-datetime.used-in-value-item {
  padding: 0;
  font-size: 16px;
}
ion-datetime.used-in-value-item .datetime-text { /* FIXME: style placehodler in same vai Item Value is */
  color: red !important
}

/* Space for Footer button */
ion-content > div:last-child {
  margin-bottom: 102px;
}

/* Checkbox style so it looks like other inputs
ion-checkbox {
  --background: var(--ion-color-light);
  --border-color: var(--ion-color-light-shade);
  // FIXME: using this ^ is checkbox barely seen
}
*/

/*
  Basic style for basic icons. (takhle treba)

  @tom_paulus,
  hotfixes.css muze fungovat jenom kdyz u kazdyho hacku napises, co, kde a proc to je.
  Minimalne poznam ze to nepatri k predchpzimu hacku :))))
*/
.orderIcon, .deliveryIcon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  color: var(--ion-text-color);
}


/* Idk why but on real iPhone is the icon cutted of by something */
.fix-iphone-clear-icon-cut-off {
  position: relative; /* heureka and lol */
}

/* Modal has main headline but it's h3 due to voice over */
ion-modal h3 {
  font-size: 26px;
}

/* Same header color for all platforms */
.sc-ion-buttons-md-s .button-clear {
  --color: var(--ion-color-primary)
}