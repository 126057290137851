/* https://github.com/moroshko/react-autosuggest#theme-prop */

.react-autosuggest__container {
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 4px;
}
.react-autosuggest__suggestions-container--open {
  max-height: 148px;
  overflow-y: auto;
  position: absolute;
  top: 48px; /* The input height */
  left: -2px;
  right: -2px;
  z-index: 2;
  background-color: var(--ion-color-light);
  border: 1px solid var(--ion-color-light-shade);
  border-top: 0 none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  color: var(--ion-text-color);

}
.react-autosuggest__container--open
.react-autosuggest__suggestions-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.react-autosuggest__suggestion {
  padding: 12px 16px;
}
.react-autosuggest__suggestion--highlighted {
  background-color: var(--ion-color-secondary-tint);
}